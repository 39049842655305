import axios from 'axios'

// develoment : http://localhost:3000/api
// production : https://icu.gg/api
// slugs 오류시 mode 또는 ssl 오류일 수 있음

const getCard = async () => {
    try {
        const { data: response } = await axios.post('https://icu.gg/api/sitemap/card/list')

        const slugs = response.map(card => {
            const timestamp = Date.parse(card.last_modified_datetime)

            if (isNaN(timestamp)) {
                console.error(`Invalid date for card with id ${card.id}:`, card.last_modified_datetime)
                return null
            }

            let lastmod = new Date(timestamp).toISOString()

            return {
                ...card,
                path: `detail?id=${card.id}`,
                lastmod: lastmod
            }
        }).filter(slug => slug !== null)

        return slugs
    } catch (error) {
        console.error(error)
        return []
    }
}

const getPost = async () => {
    try {
        const { data: response } = await axios.post('https://icu.gg/api/sitemap/board/list')

        const slugs = response.map(post => {
            const timestamp = Date.parse(post.created)

            if (isNaN(timestamp)) {
                console.error(`Invalid date for post with id ${post.id}:`, post.created)
                return null
            }

            let lastmod = new Date(timestamp).toISOString()

            return {
                ...post,
                path: `read?board_group=${post.board_group}&board_name=${post.board_name}&id=${post.id}`,
                lastmod: lastmod
            };
        }).filter(slug => slug !== null)

        return slugs
    } catch (error) {
        console.error(error)
        return []
    }
}

const getTrade = async () => {
    try {
        const {data:response} = await axios.post('https://icu.gg/api/sitemap/trade/post/list')
        
        const slugs = response.map(post => {
            const timestamp = Date.parse(post.created)

            if (isNaN(timestamp)) {
                console.error(`Invalid date for post with id ${post.id}:`, post.created)
                return null
            }

            let lastmod = new Date(timestamp).toISOString()

            return {
                ...post,
                path: `read?id=${post.id}`,
                lastmod: lastmod
            };
        }).filter(slug => slug !== null)

        return slugs
    } catch (error) {
        console.error(error)
        return []
    }
}

const getPointmall = async () => {
    try {
        const {data:response} = await axios.post('https://icu.gg/api/sitemap/pointmall/list')
        
        const slugs = response.map(post => {
            const timestamp = Date.parse(post.created)

            if (isNaN(timestamp)) {
                console.error(`Invalid date for post with id ${post.id}:`, post.created)
                return null
            }

            let lastmod = new Date(timestamp).toISOString()

            return {
                ...post,
                path: `read?id=${post.id}`,
                lastmod: lastmod
            };
        }).filter(slug => slug !== null)

        return slugs
    } catch (error) {
        console.error(error)
        return []
    }
}

const getDeck = async () => {
    try {
        const {data:response} = await axios.post('https://icu.gg/api/sitemap/deck/list')
        
        const slugs = response.map(post => {
            const timestamp = Date.parse(post.created)

            if (isNaN(timestamp)) {
                console.error(`Invalid date for post with id ${post.id}:`, post.created)
                return null
            }

            let lastmod = new Date(timestamp).toISOString()

            return {
                ...post,
                path: `read?id=${post.id}`,
                lastmod: lastmod
            };
        }).filter(slug => slug !== null)

        return slugs
    } catch (error) {
        console.error(error)
        return []
    }
}

const get_official_league = async () => {
    try {
        const {data:response} = await axios.post('https://icu.gg/api/sitemap/official_league/league')
        
        const slugs = response.map(post => {
            const timestamp = Date.parse(post.created)

            if (isNaN(timestamp)) {
                console.error(`Invalid date for post with id ${post.id}:`, post.created)
                return null
            }

            let lastmod = new Date(timestamp).toISOString()

            return {
                ...post,
                path: `read?id=${post.id}`,
                lastmod: lastmod
            };
        }).filter(slug => slug !== null)

        return slugs
    } catch (error) {
        console.error(error)
        return []
    }
}

const get_official_league_deck = async () => {
    try {
        const {data:response} = await axios.post('https://icu.gg/api/sitemap/official_league/deck')
        
        const slugs = response.map(post => {
            // const timestamp = Date.parse(post.created)

            // if (isNaN(timestamp)) {
            //     console.error(`Invalid date for post with id ${post.id}:`, post.created)
            //     return null
            // }

            let lastmod = new Date().toISOString()

            return {
                ...post,
                path: `read?id=${post.id}`,
                lastmod: lastmod
            };
        }).filter(slug => slug !== null)

        return slugs
    } catch (error) {
        console.error(error)
        return []
    }
}

const get_official_league_player = async () => {
    try {
        const {data:response} = await axios.post('https://icu.gg/api/sitemap/official_league/player')
        
        const slugs = response.map(post => {
            // const timestamp = Date.parse(post.created)

            // if (isNaN(timestamp)) {
            //     console.error(`Invalid date for post with id ${post.id}:`, post.created)
            //     return null
            // }

            let lastmod = new Date().toISOString()

            return {
                ...post,
                path: `read?id=${post.id}`,
                lastmod: lastmod
            };
        }).filter(slug => slug !== null)

        return slugs
    } catch (error) {
        console.error(error)
        return []
    }
}

export const routes = [
    // 경고
    {
        path: '/caution',
        component: () => import(/* webpackChunkName: "Common" */ '@/views/caution.vue')
    },


    // 프론트 페이지
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/default/index.vue'),
        children: [
            // 메인
            {
                path: '/',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/main.vue')
            },

            // 테스트
            {
                path: '/tests',
                meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: '/',
                        component: () => import(/* webpackChunkName: "Common" */ '@/tests/index.vue')
                    },
                ]
            },

            // 전적 관리
            {
                path: '/record',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/record.vue')
            },

            // 전적 관리 스크린샷
            {
                path: '/record_capture',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/record_capture.vue')
            },

            // 전적 관리 덱 스크린샷
            {
                path: '/deck_capture',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/deck_capture.vue')
            },

            // 출석체크
            {
                path: '/day_check',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/day_check.vue')
            },

            // 빌더 (자동이동용)
            {
                path: '/card/builder',
                meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "Common" */ '@/views/builder.vue')
            },

            // 쿠폰 페이지
            {
                path: '/businesscard',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/businesscard.vue')
            },

            // 사이트 검색
            {
                path: '/search',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/search.vue')
            },

            // 제품별 검색
            {
                path: '/set',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/set.vue')
            },

            // 리그
            {
                path: '/league',
                meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "league" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'deck',
                        component: () => import(/* webpackChunkName: "league" */ '@/layouts/empty.vue'),
                        children: [
                            { 
                                path: 'list',
                                component: () => import(/* webpackChunkName: "league" */ '@/views/league/deck/list.vue')
                            },
                            {
                                path: 'detail',
                                component: () => import(/* webpackChunkName: "league" */ '@/views/league/deck/detail.vue')
                            },
                        ]
                    },
                    {
                        path: 'player',
                        component: () => import(/* webpackChunkName: "league" */ '@/layouts/empty.vue'),
                        children: [
                            { 
                                path: 'list',
                                component: () => import(/* webpackChunkName: "league" */ '@/views/league/player/list.vue')
                            },
                            {
                                path: 'detail',
                                component: () => import(/* webpackChunkName: "league" */ '@/views/league/player/detail.vue')
                            },
                        ]
                    },
                    {
                        path: 'tournament',
                        component: () => import(/* webpackChunkName: "league" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'list',
                                component: () => import(/* webpackChunkName: "league" */ '@/views/league/tournament/list.vue')
                            },
                        ]
                    },
                ]
            },

            // 덱 레시피
            {
                path: '/deck',
                component: () => import(/* webpackChunkName: "Deck" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Deck" */ '@/views/deck/list.vue')
                    },
                    {
                        path: 'copy',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Deck" */ '@/views/deck/copy.vue')
                    },
                    {
                        path: 'update',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Deck" */ '@/views/deck/update.vue')
                    },
                    {
                        path: 'write',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Deck" */ '@/views/deck/write.vue')
                    },
                    {
                        path: 'read',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Deck" */ '@/views/deck/read.vue')
                    },
                    {
                        path: 'read?id=:id',
                        component: () => import(/* webpackChunkName: "Deck" */ '@/views/deck/read.vue'),
                        meta: {
                            sitemap: {
                                slugs: async () => await getDeck(),
                            }
                        }
                    },
                ]
            },

            // 문의하기
            {
                path: '/contact',
                meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "Contact" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Contact" */ '@/views/contact/list.vue')
                    },
                    {
                        path: 'read',
                        component: () => import(/* webpackChunkName: "Contact" */ '@/views/contact/read.vue')
                    },
                    {
                        path: 'write',
                        component: () => import(/* webpackChunkName: "Contact" */ '@/views/contact/write.vue')
                    },
                ]
            },

            // 포인트몰
            {
                path: '/pointmall',
                component: () => import(/* webpackChunkName: "Pointmall" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Pointmall" */ '@/views/pointmall/list.vue')
                    },
                    {
                        path: 'update',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Pointmall" */ '@/views/pointmall/update.vue')
                    },
                    {
                        path: 'write',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Pointmall" */ '@/views/pointmall/write.vue')
                    },
                    {
                        path: 'read',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Pointmall" */ '@/views/pointmall/read.vue')
                    },
                    {
                        path: 'read?id=:id',
                        component: () => import(/* webpackChunkName: "Pointmall" */ '@/views/pointmall/read.vue'),
                        meta: {
                            sitemap: {
                                slugs: async () => await getPointmall(),
                            }
                        }
                    },
                ]
            },

            // Auth
            {
                path: 'auth',
                meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "Auth" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/Login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/Logout.vue')
                    },
                    {
                        path: 'joinagree',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/JoinAgree.vue')
                    },
                    {
                        path: 'joinform',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/JoinForm.vue')
                    },
                    {
                        path: 'findid',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/FindId.vue')
                    },
                    {
                        path: 'findpassword',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/FindPassword.vue')
                    },
                    {
                        path: 'email_certification',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/EmailCertification.vue')
                    }
                ]
            },

            // 마이페이지
            {
                path: 'mypage',
                meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "Mypage" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'home',
                        component: () => import(/* webpackChunkName: "Mypage" */ '@/views/mypage/home.vue'),
                    },
                    {
                        path: 'point',
                        component: () => import(/* webpackChunkName: "Mypage" */ '@/views/mypage/point.vue'),
                    },
                    {
                        path: 'update',
                        component: () => import(/* webpackChunkName: "Mypage" */ '@/views/mypage/update.vue'),
                    },
                    {
                        path: 'password',
                        component: () => import(/* webpackChunkName: "Mypage" */ '@/views/mypage/password.vue'),
                    },
                    {
                        path: 'address',
                        component: () => import(/* webpackChunkName: "Mypage" */ '@/views/mypage/address.vue'),
                    },
                    {
                        path: 'alarm',
                        component: () => import(/* webpackChunkName: "Mypage" */ '@/views/mypage/alarm.vue'),
                    },
                    {
                        path: 'message',
                        component: () => import(/* webpackChunkName: "Mypage" */ '@/views/mypage/message.vue'),
                    },
                    // 프로필
                    {
                        path: 'profile',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/mypage/profile.vue')
                    },
                    
                    // 공식대회
                    {
                        path: 'official_league',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/mypage/official_league.vue')
                    },
                ]
            },

            // 카드검색 & 카드상세
            {
                path: 'card',
                component: () => import(/* webpackChunkName: "Card" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Card" */ '@/views/card/list.vue'),
                    },
                    {
                        path: 'detail',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Card" */ '@/views/card/detail.vue'),
                    },
                    {
                        path: 'detail?id=:id',
                        component: () => import(/* webpackChunkName: "Card" */ '@/views/card/detail.vue'),
                        meta: {
                            sitemap: {
                                slugs: async () => await getCard(),
                            }
                        }
                    },
                ]
            },

            // 카드거래
            {
                path: 'trade',
                component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade.vue'),
                children: [
                    {
                        path: 'post',
                        component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade/post.vue'),
                        children: [
                            {
                                path: 'list',
                                name: 'TradePostList',
                                component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade/post/list.vue'),
                            },
                            {
                                path: 'write',
                                meta: { sitemap: { ignoreRoute: true } },
                                component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade/post/write.vue'),
                            },
                            {
                                path: 'update',
                                meta: { sitemap: { ignoreRoute: true } },
                                component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade/post/update.vue'),
                            },
                            {
                                path: 'read',
                                meta: { sitemap: { ignoreRoute: true } },
                                component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade/post/read.vue'),
                            },
                            {
                                path: 'read?id=:id',
                                component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade/post/read.vue'),
                                meta: {
                                    sitemap: {
                                        slugs: async () => await getTrade(),
                                    }
                                }
                            },
                        ]
                    },
                    {
                        path: 'card_list',
                        component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade/card_list.vue'),
                    },
                    {
                        path: 'log',
                        component: () => import(/* webpackChunkName: "Trade" */ '@/views/trade/log.vue'),
                    }
                ]
            },

            // 콜렉션북
            {
                path: '/collection',
                meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "Collection" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'dashboard',
                        component: () => import(/* webpackChunkName: "Collection" */ '@/views/collection/dashboard.vue')
                    },
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Collection" */ '@/views/collection/list.vue')
                    },
                    {
                        path: 'collect',
                        component: () => import(/* webpackChunkName: "Collection" */ '@/views/collection/collect.vue')
                    },
                    // 챌린지북
                    {
                        path: 'challengebook',
                        component: () => import(/* webpackChunkName: "Board" */ '@/layouts/empty.vue'),
                        children: [
                            // 목록
                            {
                                path: 'list',
                                component: () => import(/* webpackChunkName: "Board" */ '@/views/collection/challengebook/list.vue'),
                            },

                            // 상세
                            {
                                path: 'read',
                                component: () => import(/* webpackChunkName: "Board" */ '@/views/collection/challengebook/read.vue'),
                            },
                        ]
                    },
                ]
            },

            // 카드 랭킹
            {
                path: 'rank',
                component: () => import(/* webpackChunkName: "Card" */ '@/views/rank.vue'),
            },

            // # 공식 대회
            {
                path: '/official_league',
                component: () => import(/* webpackChunkName: "official_league" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/list.vue')
                    },
                    {
                        path: 'write',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/write.vue')
                    },
                    {
                        path: 'update',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/update.vue')
                    },
                    {
                        path: 'read',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/read.vue')
                    },
                    {
                        path: 'read?id=:id',
                        component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/read.vue'),
                        meta: {
                            sitemap: {
                                slugs: async () => await get_official_league(),
                            }
                        }
                    },

                    // # 플레이어
                    {
                        path: 'player',
                        component: () => import(/* webpackChunkName: "official_league" */ '@/layouts/empty.vue'),
                        children: [
                            { 
                                path: 'list',
                                component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/player/list.vue')
                            },
                            {
                                path: 'detail',
                                meta: { sitemap: { ignoreRoute: true } },
                                component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/player/detail.vue')
                            },
                            {
                                path: 'detail?id=:id',
                                component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/player/detail.vue'),
                                meta: {
                                    sitemap: {
                                        slugs: async () => await get_official_league_player(),
                                    }
                                }
                            },
                        ]
                    },
                    
                    // # 덱 순위
                    {
                        path: 'deck_rank',
                        component: () => import(/* webpackChunkName: "official_league" */ '@/layouts/empty.vue'),
                        children: [
                            { 
                                path: 'list',
                                component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/deck_rank/list.vue')
                            },
                            {
                                path: 'detail',
                                meta: { sitemap: { ignoreRoute: true } },
                                component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/deck_rank/detail.vue')
                            },
                            {
                                path: 'detail?id=:id',
                                component: () => import(/* webpackChunkName: "official_league" */ '@/views/official_league/deck_rank/detail.vue'),
                                meta: {
                                    sitemap: {
                                        slugs: async () => await get_official_league_deck(),
                                    }
                                }
                            },
                        ]
                    },
                ]
            },

            // 커뮤니티
            {
                path: 'board',
                component: () => import(/* webpackChunkName: "Board" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'list',
                        name: 'list',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Board" */ '@/views/board/list.vue'),
                    },
                    {
                        path: 'read',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Board" */ '@/views/board/read.vue'),
                    },
                    {
                        path: 'read?board_group=:board_group&amp;board_name=:board_name&amp;id=:id',
                        component: () => import(/* webpackChunkName: "Board" */ '@/views/board/read.vue'),
                        meta: {
                            sitemap: {
                                slugs: async () => await getPost(),
                            }
                        }
                    },
                    {
                        path: 'update',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Board" */ '@/views/board/update.vue'),
                    },
                    {
                        path: 'write',
                        meta: { sitemap: { ignoreRoute: true } },
                        component: () => import(/* webpackChunkName: "Board" */ '@/views/board/write.vue'),
                    }
                ]
            },

            // Policy
            {
                path: 'policy',
                // meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "Policy" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'apiguide',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/ApiGuide.vue'),
                        meta: { title: "한글 포켓몬카드 API" },
                    },
                    {
                        path: 'rule',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/Rule.vue'),
                        meta: { title: "이용약관" },
                    },
                    {
                        path: 'privacy',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/Privacy.vue'),
                        meta: { title: "개인정보처리방침" },
                    },
                    {
                        path: 'denyemail',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/DenyEmail.vue'),
                        meta: { title: "이메일무단수집거부" },
                    },
                    {
                        path: 'pointguide',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/PointGuide.vue'),
                        meta: { title: "포인트 획득 가이드" },
                    },
                    {
                        path: 'creditsandcontact',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/CreditsAndContact.vue'),
                        meta: { title: "포인트 획득 가이드" },
                    }
                ]
            }
        ]
    },

    // 관리자 페이지
    {
        path: '/admin',
        meta: { sitemap: { ignoreRoute: true } },
        component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/admin/index.vue'),
        children: [
            // 대시보드
            {
                path: 'dashboard',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/dashboard.vue'),
            },

            // 메인 관리
            {
                path: 'main',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'contact',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/main/contact.vue'),
                    },
                    {
                        path: 'alarm',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/main/alarm.vue'),
                    },
                    {
                        path: 'visited_log',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/main/visited_log.vue'),
                    },
                ]
            },

            // 배너 관리
            {
                path: 'banner',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'ads',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/banner/ads.vue'),
                    },
                    {
                        path: 'main',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/banner/main.vue'),
                    },
                ]
            },

            // 카드 관리
            {
                path: 'card',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'delete',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/delete.vue'),
                    },
                    {
                        path: 'dump',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/dump.vue'),
                    },
                    {
                        path: 'new',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/new.vue'),
                    },
                    {
                        path: 'new_api',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/new_api.vue'),
                    },
                    {
                        path: 'translate',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/translate.vue'),
                    },
                    {
                        path: 'translated',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/translated.vue'),
                    },
                    {
                        path: 'pre_card',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/pre_card.vue'),
                    },
                    {
                        path: 'image_match',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/image_match.vue'),
                    },
                    {
                        path: 'set',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/set.vue'),
                    },
                    {
                        path: 'review',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/review.vue'),
                    },
                    {
                        path: 'excel',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/excel.vue'),
                    },
                    {
                        path: 'copy',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/card/copy.vue'),
                    }
                ]
            },

            // 카드검색 관리
            {
                path: 'search',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'basic',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/search/basic.vue'),
                    },
                    {
                        path: 'rarity',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/search/rarity.vue'),
                    },
                    {
                        path: 'supertype',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/search/supertype.vue'),
                    }
                ]
            },

            // 카드거래 관리
            {
                path: 'trade',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'etc',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/trade/etc.vue')
                    },
                    {
                        path: 'group',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/trade/group.vue')
                    },
                    {
                        path: 'price',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/trade/price.vue')
                    },
                    {
                        path: 'notice',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/trade/notice.vue')
                    },
                    {
                        path: 'request',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/trade/request.vue')
                    }
                ]
            },

            // 리그 관리
            {
                path: 'league',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'auto',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/league/auto.vue')
                    },
                    {
                        path: 'deck_name',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/league/deck_name.vue')
                    },
                    {
                        path: 'official_league_type',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/league/official_league_type.vue')
                    },
                    {
                        path: 'uid_apply',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/league/uid_apply.vue')
                    },
                ]
            },

            // 덱레시피 관리
            {
                path: 'deck',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'notice',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/deck/notice.vue')
                    },
                    {
                        path: 'tag',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/deck/tag.vue')
                    },
                    {
                        path: 'season',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/deck/season.vue')
                    },
                    {
                        path: 'renewal',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/deck/renewal.vue')
                    },
                    {
                        path: 'ptcgl',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/deck/ptcgl.vue')
                    },
                    {
                        path: 'regulation_mark',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/deck/regulation_mark.vue'),
                    },
                ]
            },

            // 제보 관리
            {
                path: 'provide',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'official_league',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/provide/official_league.vue'),
                    },
                    {
                        path: 'image',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/provide/image.vue'),
                    },
                    {
                        path: 'info',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/provide/info.vue'),
                    },
                    {
                        path: 'bug',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/provide/bug.vue'),
                    }
                ]
            },

            // 포인트 관리
            {
                path: 'point',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'setting',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/point/setting.vue'),
                    },
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/point/list.vue'),
                    },
                ]
            },

            // 포인트몰 관리
            {
                path: 'pointmall',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'banner',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/pointmall/banner.vue'),
                    },
                    {
                        path: 'product',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/pointmall/product.vue'),
                    },
                    {
                        path: 'order',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/pointmall/order.vue'),
                    },
                    {
                        path: 'guide',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/pointmall/guide.vue'),
                    },
                ]
            },

            // 유저 관리
            {
                path: 'user',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'normal',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/user/normal.vue'),
                    },
                    {
                        path: 'reported',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/user/reported.vue'),
                    },
                    {
                        path: 'ban_log',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/user/ban_log.vue'),
                    },
                    {
                        path: 'banned',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/user/banned.vue'),
                    },
                    {
                        path: 'ban_ip',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/user/ban_ip.vue'),
                    }
                ]
            },

            // 게시판 관리
            {
                path: 'board',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'category',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/board/category.vue'),
                    },
                    {
                        path: 'post',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/board/post.vue'),
                    },
                    {
                        path: 'blinded_post',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/board/blinded_post.vue'),
                    },
                    {
                        path: 'comment',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/board/comment.vue'),
                    },
                    {
                        path: 'ads',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/board/ads.vue'),
                    },
                    {
                        path: 'db_request',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/board/db_request.vue'),
                    },
                ]
            },

            // 직원 관리
            {
                path: 'manager',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'permission',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/manager/permission.vue'),
                    },
                    {
                        path: 'tester',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/manager/tester.vue'),
                    },
                    {
                        path: 'list',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/manager/list.vue'),
                    },
                    {
                        path: 'log',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/manager/log.vue'),
                    },
                ]
            },
        ]
    }
]